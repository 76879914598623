import { getUserEmail, getCompanySession } from '@helpers/token';

export const pageview = url => {
  if (window.gtag) {
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      page_path: url
    });
  }
};

export const event = ({ action, params }) => {
  if (window.gtag) {
    window.gtag('event', action, params);
  }
};

export class Analytics {
  static dataLayer;

  static trackEventWithPayload(payload) {
    if (typeof window === 'undefined') {
      return;
    }
    if (!this.dataLayer) {
      this.dataLayer = window.dataLayer || [];
    }
    this.dataLayer.push(payload);
  }

  static pushUserAttributes() {
    const email = getUserEmail();
    const companySession = getCompanySession();

    if (email && email !== '') {
      this.trackEventWithPayload({
        'DLV - User Email': email,
        'DLV - Apollo Id': companySession?.apollo_id,
        'DLV - Merchant Name': companySession?.name
      });
    }
  }

  static trackVendorSubmissionCopyLink() {
    this.trackEventWithPayload({
      event: 'SPEND_VENDOR_SUBMISSION_COPY_LINK'
    });
  }

  static trackVendorSubmitVendorSubmissionForm() {
    this.trackEventWithPayload({
      event: 'SPEND_VENDOR_SUBMISSION_FORM_SUBMIT'
    });
  }

  static trackCreateCategoryFromSpendTransaction() {
    this.trackEventWithPayload({
      event: 'SPEND_TRANSACTION_CREATE_MASTER_CATEGORY'
    });
  }

  static trackSpendCreateSingleTransaction() {
    this.trackEventWithPayload({
      event: 'SPEND_SUBMIT_SINGLE_TRANSACTION'
    });
  }

  static trackSpendCreateBatchTransaction() {
    this.trackEventWithPayload({
      event: 'SPEND_SUBMIT_BATCH_TRANSACTION'
    });
  }

  static trackSpendChoosePaginationSize(value) {
    this.trackEventWithPayload({
      event: 'SPEND_TRANSACTION_CHOOSE_PAGINATION',
      SPEND_TRANSACTION_PAGINATION_VALUE: value
    });
  }
}
