import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { getUserId } from '@helpers/token';
import { setVisitedURL } from '@helpers/previousUrl';

export default function RouteGuard({ children }) {
  const router = useRouter();
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    // on initial load - run auth check
    authCheck(router.asPath);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  function authCheck(url) {
    // redirect to login page if accessing a private page and not logged in
    const path = url.split('?')[0];
    const routePrefix = path?.split('/')[1];
    const userId = getUserId();

    if (routePrefix === 'dashboard' && !userId) {
      setAuthorized(false);
      router.push({ pathname: '/login' });

      setVisitedURL(router.asPath);
    } else {
      setAuthorized(true);
    }
  }

  return authorized && children;
}
