/* eslint-disable react/react-in-jsx-scope */
import '@styles/main.scss';
import 'react-datepicker/dist/react-datepicker.css';
import '@fontsource/open-sans';
import _ from 'lodash';

import { Provider } from 'react-redux';
import App from 'next/app';
import Head from 'next/head';
import withRedux from 'next-redux-wrapper';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { DefaultSeo } from 'next-seo';
import Router from 'next/router';
import NProgress from 'nprogress';
import cookie from 'cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import AuthGuard from '@components/auth-guard/AuthGuard';

import * as ga from '@helpers/analytics';
import { appWithTranslation, i18n } from '@helpers/i18n';
import { LAUNCHDARKLY_SDK_CLIENT_SIDE_ID } from '@configs/keys';

import 'antd/dist/antd.css';

import { initStore } from '../src/store/store';

const queryClient = new QueryClient();

NProgress.configure({ showSpinner: true });

Router.onRouteChangeStart = () => {
  NProgress.start();
  document.body.classList.add('loading');
};

Router.onRouteChangeComplete = url => {
  NProgress.done();
  document.body.classList.remove('loading');
  if (process.browser) {
    ga.pageview(url);
  }
};

Router.onRouteChangeError = () => {
  NProgress.done();
  document.body.classList.remove('loading');
};

// Hack as Component.getInitialProps is always undefined smh
const translationNamespaces = {
  '/secured': ['prepaid-home', 'common'],
  '/secured/apply': ['prepaid-apply', 'common'],
  '/secured/apply-success': ['prepaid-success'],
  '/login': ['auth', 'common'],
  '/secured-dashboard': ['prepaid-dashboard'],
  '/secured/activate-card': ['prepaid-activate', 'common']
};

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    const { originalUrl } = ctx.req || {};

    // Fix problem with i18next mismatch server vs client
    if (ctx.req?.headers?.cookie) {
      const cookies = cookie.parse(ctx.req.headers.cookie);
      i18n.changeLanguage(cookies['next-i18next']);
    }
    const pageProps = Object.assign(
      {
        namespacesRequired: translationNamespaces[originalUrl] || ['common']
      },
      Component.getInitialProps ? await Component.getInitialProps(ctx) : {}
    );
    return {
      pageProps,
      originalUrl
    };
  }

  render() {
    const { Component, pageProps, store } = this.props;
    return (
      <>
        <DefaultSeo
          title='Apollo'
          description='Apollo Spend Management'
          openGraph={{
            type: 'website',
            locale: 'en_IE',
            url: '',
            site_name: ''
          }}
        />
        <Head>
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1, shrink-to-fit=no'
          />
        </Head>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <AuthGuard>
              <Component {...pageProps} />
            </AuthGuard>
          </Provider>
        </QueryClientProvider>
      </>
    );
  }
}

export default _.flow(
  appWithTranslation,
  withRedux(initStore),
  withLDProvider({
    clientSideID: LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
    reactOptions: {
      useCamelCaseFlagKeys: false
    }
  })
)(MyApp);
